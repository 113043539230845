<template>
  <v-card
    class="px-8 py-8"
  >
    <span class="">Exportação de dados</span>

    <v-spacer />

    <div class="d-flex mt-4">
      <v-autocomplete
        v-model="reportSelected"
        :items="reportsList"
        item-text="title"
        item-value="title"
        label="Tipo de relatório"
        dense
        outlined
        class="mr-4"
      ></v-autocomplete>

      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="isoDate"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formattedDate"
            dense
            outlined
            label="Mês e ano referência"
            :append-icon="icons.mdiCalendar"
            readonly
            style=""
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="isoDate"
          type="month"
          width="440"
          no-title
          scrollable
          @change="(date) => ($refs.menu.save(date), (isoDate = date))"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

      <v-btn
        color="warning"
        outlined
        data-test="adm-system-button"
        class="ml-4"
        :disabled="isExporting"
        @click="exportDataDRE"
      >
        <v-icon class="mr-2">
          {{ icons.mdiDatabaseArrowDownOutline }}
        </v-icon>
        {{ isExporting ? 'Exportando...' : 'Exportar' }}
      </v-btn>

      <!-- <v-btn
        color="primary"
        outlined
        data-test="adm-system-button"
        class="ml-4"
      >
        <v-icon class="mr-2">
          {{ icons.mdiCloudSyncOutline }}
        </v-icon>
        REPROCESSAR
      </v-btn> -->
    </div>

    <span class="">Reprocessamento de dados</span>
    <v-form
      ref="form"
      class="d-flex mt-4"
    >
      <v-autocomplete
        v-model="reportSelectedReprocess"
        :items="reporcessList"
        :rules="[rules.required]"
        label="Tipo de processo"
        dense
        outlined
        class="mr-4"
      ></v-autocomplete>

      <v-menu
        ref="menuReprocess"
        v-model="menuReprocess"
        :close-on-content-click="false"
        :return-value.sync="isoDateReprocess"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formattedDateReprocess"
            dense
            outlined
            label="Mês e ano referência"
            :append-icon="icons.mdiCalendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="isoDateReprocess"
          type="month"
          width="430"
          no-title
          scrollable
          @change="(date) => ($refs.menuReprocess.save(date), (isoDateReprocess = date))"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menuReprocess = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menuReprocess.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

      <!-- <v-btn
        color="warning"
        outlined
        data-test="adm-system-button"
        class="ml-4"
        :disabled="isExporting"
        @click="exportDataDRE"
      >
        <v-icon class="mr-2">
          {{ icons.mdiDatabaseArrowDownOutline }}
        </v-icon>
        {{ isExporting ? 'Exportando...' : 'Exportar' }}
      </v-btn> -->

      <v-btn
        color="primary"
        outlined
        data-test="adm-system-button"
        class="ml-4"
        :disabled="loadingReprocess"
        @click="reprocessPlan"
      >
        <v-icon class="mr-2">
          {{ icons.mdiCloudSyncOutline }}
        </v-icon>
        {{ loadingReprocess ? 'Reprocessando...' : 'REPROCESSAR' }}
      </v-btn>
    </v-form>

    <span class="">Processamento via planilha SELF</span>

    <div class="container-attachment">
      <v-file-input
        v-model="file"
        dense
        class=""
        multiple
        outlined
        label="Anexo"
      />
      <!-- aqui -->
      <v-menu
        ref="menuProcess"
        v-model="menuProcess"
        :close-on-content-click="false"
        :return-value.sync="isoDateProccess"
        transition="scale-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="formattedDateProccess"
            dense
            outlined
            label="Mês e ano referência"
            :append-icon="icons.mdiCalendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="isoDateProccess"
          type="month"
          width="500"
          no-title
          scrollable
          @change="(date) => ($refs.menuProcess.save(date), (isoDateProccess = date))"
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menuProcess = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menuProcess.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-btn
        color="primary"
        outlined
        data-test="adm-system-button"
        class="ml-4 mb-6"
        @click="exportDataDREProcessSelf"
      >
        <v-icon class="mr-2">
          {{ icons.mdiArchiveArrowUp }}
        </v-icon>
        <span v-if="!loadingProcessArchive">SUBMETER</span>

        <v-progress-circular
          v-else
          color="info"
          indeterminate
        ></v-progress-circular>
      </v-btn>
    </div>

    <!-- <v-data-table
      v-model="itemTableSelected"
      :headers="headers"
      :items="[]"
      :loading="isLoadingTableData"
      class="text-no-wrap"
      loading-text="Carregando dados..."
      disable-sort
    >
      <template v-slot:no-data>
        <NoDataInterface />
      </template>
    </v-data-table> -->
  </v-card>
</template>

<script>

// import NoDataInterface from '@/components/charts/NoDataInterface.vue'
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { getVuetify } from '@core/utils'
import {
  mdiArchiveArrowUp,
  mdiCalendar,
  mdiCloudSyncOutline,
  mdiDatabaseArrowDownOutline,
  mdiFilterVariant,
  mdiPlaylistPlay,
} from '@mdi/js'
import { saveAs } from 'file-saver'
import moment from 'moment'
import 'moment/locale/pt-br'

const ExcelJS = require('exceljs')

moment.locale('pt-br')

export default {
  components: {
    // NoDataInterface,
  },
  mixins: [messages, formatters],
  data() {
    return {
      headers: [
        {
          text: 'FILIAL',
          value: 'region',
          sortable: false,
          align: '',
        },
        {
          text: 'LOJA',
          value: 'fantasy_name',
          sortable: false,
          align: '',
        },
        {
          text: 'FUNCIONÁRIO',
          value: 'name',
          sortable: false,
          align: '',
        },
        {
          text: 'COD. SELF',
          value: 'cod_self',
          sortable: false,
          align: '',
        },
      ],
      file: [],
      loadingProcessArchive: false,
      icons: {
        mdiPlaylistPlay,
        mdiCloudSyncOutline,
        mdiFilterVariant,
        mdiCalendar,
        mdiDatabaseArrowDownOutline,
        mdiArchiveArrowUp,
      },
      isoDate: moment().format('YYYY-MM'),
      isoDateReprocess: moment().format('YYYY-MM'),
      isoDateProccess: moment().format('YYYY-MM'),
      menuReprocess: false,
      menuProcess: false,
      menu: false,
      isExporting: false,
      reportSelectedReprocess: '',
      reportSelected: '',
      reportsList: [
        { title: 'DRE de Serviços', type: 1 },
        { title: 'DRE de Receitas', type: 2 },
        { title: 'DRE de Despesas', type: 3 },
        { title: 'DRE de Vendas de Veículos', type: 4 },
        { title: 'DRE de peças', type: 5 },
        { title: 'Todos os DRE', type: 9 },
        { title: 'Venda de veículos', type: 6 },
        { title: 'Venda de serviços', type: 7 },
        { title: 'Venda de peças', type: 8 },
        { title: 'Venda de cotas', type: 11 },
        { title: 'Todas as vendas', type: 10 },
      ],
      reporcessList: ['PROCESSAR TODA A DRE PARA O PLANO', ' PROCESSAR TODAS AS VENDAS PARA O PLANO'],
      itemTableSelected: [],
      salesListId: [],
      isLoadingTableData: false,
      loadingReprocess: false,
    }
  },
  computed: {
    formattedDate: {
      get() {
        return moment(this.isoDate, 'YYYY-MM').format('MM/YYYY').toUpperCase()
      },
      set(value) {
        if (!value) {
          this.isoDate = value

          return
        }
        this.isoDate = moment(value, 'MMMM YYYY').format('YYYY-MM')
      },
    },
    formattedDateReprocess: {
      get() {
        return moment(this.isoDateReprocess, 'YYYY-MM').format('MM/YYYY').toUpperCase()
      },
      set(value) {
        if (!value) {
          this.isoDateReprocess = value

          return
        }
        this.isoDateReprocess = moment(value, 'MMMM YYYY').format('YYYY-MM')
      },
    },
    formattedDateProccess: {
      get() {
        return moment(this.isoDateProccess, 'YYYY-MM').format('MM/YYYY').toUpperCase()
      },
      set(value) {
        if (!value) {
          this.isoDateProccess = value

          return
        }
        this.isoDateProccess = moment(value, 'MMMM YYYY').format('YYYY-MM')
      },
    },
    isDark() {
      const $vuetify = getVuetify()

      return $vuetify.theme.dark
    },
  },
  watch: {
    itemTableSelected(select) {
      const items = []
      select.map(({ id }) => {
        items.push(id)
      })

      this.salesListId = items
    },
  },
  methods: {
    async reprocessPlan() {
      const body = {
        competencia: `${this.isoDateReprocess}-01`,
      }

      const isValid = this.$refs.form.validate()

      if (!isValid) {
        this.showMessage({ title: 'Formulário inválido', text: 'Verifique os campos obrigatórios' })

        return
      }

      // console.log(body)

      this.loadingReprocess = true

      try {
        if (this.reportSelectedReprocess === 'PROCESSAR TODA A DRE PARA O PLANO') {
          const response = await axiosIns.post('/api/v1/utilities/api_for_all_strategy_consumption/send_dre_payload', body)
          console.log(response.data.msg)

          await this.$swal({
            icon: 'success',
            title: 'Dados processados!',
            text: response.data.msg,
            showConfirmButton: false,
            timer: 6000,
          })
        } else {
          const response = await axiosIns.post('/api/v1/utilities/api_for_all_strategy_consumption/send_sales_payload', body)

          await this.$swal({
            icon: 'success',
            title: 'Dados processados!',
            text: response.data.msg,
            showConfirmButton: false,
            timer: 6000,
          })
          console.log(response.data.msg)
        }
      } catch (error) {
        console.log(error)
        await this.$swal({
          icon: 'error',
          title: 'ERRO!',
          text: 'Ocorreu um erro ao processar o arquivo',
        })
      } finally {
        this.loadingReprocess = false
      }
    },

    async reprocessArchiveSelf() {
      this.loadingProcessArchive = true

      const formData = new FormData()

      const dateSplitted = this.isoDateProccess.split('-')
      const year = Number(dateSplitted[0])
      const month = Number(dateSplitted[1])

      this.file.forEach(async file => {
        formData.append('files[]', file)
      })

      formData.append('year', year)
      formData.append('month', month)

      let blob = null

      try {
        const response = await axiosIns.post('/api/v1/utilities/api_for_all_strategy_consumption/process_dre_payroll', formData)

        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('sheet')

        worksheet.columns = [
          { header: 'MES', key: 'MES' },
          { header: 'ANO', key: 'ANO' },
          { header: 'DATA', key: 'DATA' },
          { header: 'COD_UNIDADE', key: 'COD_UNIDADE' },
          { header: 'COD_CC', key: 'COD_CC' },
          { header: 'DESC_CC', key: 'DESC_CC' },
          { header: 'COD_CONTA', key: 'COD_CONTA' },
          { header: 'DESC_CONTA', key: 'DESC_CONTA' },
          { header: 'DOCUMENTO', key: 'DOCUMENTO' },
          { header: 'NATUREZA', key: 'NATUREZA' },
          { header: 'VALOR', key: 'VALOR' },
          { header: 'HISTORICO', key: 'HISTORICO' },
          { header: 'COD_PROJETO', key: 'COD_PROJETO' },
          { header: 'GERADOR', key: 'GERADOR' },
          { header: 'COD_DIMENSAO1', key: 'COD_DIMENSAO1' },
          { header: 'RATEIO', key: 'RATEIO' },
        ]

        response.data.map(itemTable => {
          worksheet.addRow([
            itemTable.MES,
            itemTable.ANO,
            itemTable.DATA,
            itemTable.COD_UNIDADE,
            itemTable.COD_CC,
            itemTable.DESC_CC,
            itemTable.COD_CONTA,
            itemTable.DESC_CONTA,
            itemTable.DOCUMENTO,
            itemTable.NATUREZA,
            itemTable.VALOR,
            itemTable.HISTORICO,
            itemTable.COD_PROJETO,
            itemTable.GERADOR,
            itemTable.COD_DIMENSAO1,
            itemTable.RATEIO,
          ])
        })

        await workbook.xlsx.writeBuffer().then(data => {
          blob = new File([data], 'DRE-SELF', {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        })
      } catch (error) {
        console.log(error)
        await this.$swal({
          icon: 'error',
          title: 'ERRO!',
          text: 'Ocorreu um erro ao processar o arquivo',
        })
      } finally {
        this.loadingProcessArchive = false
      }

      return blob
    },

    async exportDataDREProcessSelf() {
      const file = await this.reprocessArchiveSelf()

      if (file) {
        saveAs(file)
      }
    },

    async exportDataDRE() {
      const file = await this.handleFilter()

      if (file) {
        saveAs(file)
      }
    },

    async handleFilter() {
      const endpoints = {
        'DRE de Serviços': '/api/v1/utilities/api_for_all_strategy_consumption/from_cloud_to_dre_services',
        'DRE de peças': '/api/v1/utilities/api_for_all_strategy_consumption/from_cloud_to_dre_parts',
        'DRE de Receitas': '/api/v1/utilities/api_for_all_strategy_consumption/from_cloud_to_dre_revenues',
        'DRE de Despesas': '/api/v1/utilities/api_for_all_strategy_consumption/from_cloud_to_dre_expenses',
        'DRE de Vendas de Veículos': '/api/v1/utilities/api_for_all_strategy_consumption/from_cloud_to_dre_vehicles',
        'Venda de veículos': '/api/v1/utilities/api_for_all_strategy_consumption/from_cloud_to_vehicles_sales',
        'Venda de serviços': '/api/v1/utilities/api_for_all_strategy_consumption/from_cloud_to_services_sale',
        'Venda de peças': '/api/v1/utilities/api_for_all_strategy_consumption/from_cloud_to_parts_sales',
        'Todos os DRE': '/api/v1/utilities/api_for_all_strategy_consumption/create_all_dres',
        'Todas as vendas': '/api/v1/utilities/api_for_all_strategy_consumption/create_all_sales',
        'Venda de cotas': '/api/v1/utilities/api_for_all_strategy_consumption/from_cloud_to_consortium_sales',
      }

      const url = endpoints[this.reportSelected]

      if (!url) {
        this.showMessage({
          icon: 'error',
          title: 'Informe o tipo de relatório e a data.',
        })

        return
      }

      const dateSplitted = this.isoDate.split('-')
      const year = (dateSplitted[0])
      const month = (dateSplitted[1])

      const body = {
        month,
        year,
      }

      let blob = null

      console.log(this.reportSelected)

      const sales = ['Venda de veículos', 'Venda de serviços', 'Venda de peças', 'Venda de cotas', 'Todas as vendas']

      try {
        this.isExporting = true

        const response = await axiosIns.post(url, body)

        const workbook = new ExcelJS.Workbook()
        const worksheet = workbook.addWorksheet('sheet')

        if (sales.includes(this.reportSelected)) {
          worksheet.columns = [
            { header: 'MES', key: 'MES' },
            { header: 'ANO', key: 'ANO' },
            { header: 'DATAENTRADA', key: 'DATAENTRADA' },
            { header: 'UNIDADE', key: 'UNIDADE' },
            { header: 'PRODUTO', key: 'PRODUTO' },
            { header: 'QUANTIDADE', key: 'QUANTIDADE' },
            { header: 'VALOR_UNITARIO', key: 'VALOR_UNITARIO' },
            { header: 'CODIGODIMENSAO1', key: 'CODIGODIMENSAO1' },
            { header: 'CODIGOCATEGORIA', key: 'CODIGOCATEGORIA' },
            { header: 'CODIGOCATEGORIA1', key: 'CODIGOCATEGORIA1' },
            { header: 'CODIGOPROJETO', key: 'CODIGOPROJETO' },
            { header: 'VALORIZACAO', key: 'VALORIZACAO' },
            { header: 'CUSTO_UNITARIO_DE_AQUISICAO', key: 'CUSTO_UNITARIO_DE_AQUISICAO' },
          ]

          response.data.map(itemTable => {
            worksheet.addRow([
              itemTable.MES,
              itemTable.ANO,
              itemTable.DATAENTRADA,
              itemTable.UNIDADE,
              itemTable.PRODUTO,
              itemTable.QUANTIDADE,
              itemTable.VALOR_UNITARIO,
              itemTable.CODIGODIMENSAO1,
              itemTable.CODIGOCATEGORIA,
              itemTable.CODIGOCATEGORIA1,
              itemTable.CODIGOPROJETO,
              itemTable.VALORIZACAO,
              itemTable.CUSTO_UNITARIO_DE_AQUISICAO,
            ])
          })
        } else {
          worksheet.columns = [
            { header: 'MES', key: 'MES' },
            { header: 'ANO', key: 'ANO' },
            { header: 'DATA', key: 'DATA' },
            { header: 'COD_UNIDADE', key: 'COD_UNIDADE' },
            { header: 'COD_CC', key: 'COD_CC' },
            { header: 'DESC_CC', key: 'DESC_CC' },
            { header: 'COD_CONTA', key: 'COD_CONTA' },
            { header: 'DESC_CONTA', key: 'DESC_CONTA' },
            { header: 'DOCUMENTO', key: 'DOCUMENTO' },
            { header: 'NATUREZA', key: 'NATUREZA' },
            { header: 'VALOR', key: 'VALOR' },
            { header: 'HISTORICO', key: 'HISTORICO' },
            { header: 'GERADOR', key: 'GERADOR' },
            { header: 'RATEIO', key: 'RATEIO' },
          ]

          response.data.map(itemTable => {
            worksheet.addRow([
              itemTable.MES,
              itemTable.ANO,
              itemTable.DATA,
              itemTable.COD_UNIDADE,
              itemTable.COD_CC,
              itemTable.DESC_CC,
              itemTable.COD_CONTA,
              itemTable.DESC_CONTA,
              itemTable.DOCUMENTO,
              itemTable.NATUREZA,
              itemTable.VALOR,
              itemTable.HISTORICO,
              itemTable.GERADOR,
              itemTable.RATEIO,
            ])
          })
        }

        await workbook.xlsx.writeBuffer().then(data => {
          blob = new File([data], 'DRE', {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        })
      } catch (error) {
        this.showMessage({
          icon: 'error',
          title: `Algo deu errado ao buscar os dados. ${error}`,
        })
      } finally {
        this.isExporting = false
      }

      // eslint-disable-next-line consistent-return
      return blob
    },
  },
}
</script>

<style scoped>
.container-attachment{
  margin-top: 16px;

  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
}

.container-input{
  width: 100%;
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
